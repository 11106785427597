//CSS per LOGIN WP
.login h1 a {
    background-image: url("../img/logo.png");
    background-size: 240px;
    width: 240px;
    height: 96px;
}
.wp-core-ui .button-primary {
    background: #1396c2;
    border-color: #1396c2;
    box-shadow: none;
    color: #fff;
    text-decoration: none;
    text-shadow: none;
}
.wp-core-ui .button-primary:hover {
    background: #fff;
    border-color: #1396c2;
    color: #1396c2;
}
.wp-core-ui .button-primary.active, .wp-core-ui .button-primary.active:focus, .wp-core-ui .button-primary.active:hover, .wp-core-ui .button-primary:active {
    background: #fff;
    border-color: #1396c2;
    color: #1396c2;
    box-shadow: none;
}
.wp-core-ui .button-primary.focus, .wp-core-ui .button-primary:focus {
    background: #fff;
    border-color: #1396c2;
    color: #1396c2;
    box-shadow: none;
}